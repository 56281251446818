
























































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentHostType,
  FundAppropriateApplyCreateOrUpdateDto,
  FundAppropriateApplyDto,
  FundDto,
  FundDtoPagedResultDto,
  FundProjectDto,
  FundProjectDtoPagedResultDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateFundAppropriateApply extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId?: number = 0;
  form: FundAppropriateApplyCreateOrUpdateDto = {
    id: 0,
    moneyAmount: 0,
    projectId: undefined,
  };
  moneyBig = "零元";
  contactType: any = [
    {
      text: "手机",
      value: 1,
    },
    {
      text: "邮箱",
      value: 2,
    },
    {
      text: "两种皆可",
      value: 3,
    },
  ];
  donationType: any = [
    {
      text: "物品捐赠",
      value: 1,
    },
    {
      text: "场地/其他捐赠",
      value: 2,
    },
  ];
  applyTime = "零元";
  typeList: any = [];
  fundList: FundDto[] = [];
  projectList: FundProjectDto[] = [];
  btnDisabled = false;

  get hostTypeFile() {
    return AttachmentHostType.FundAppropriateApply;
  }

  created() {
    this.getCurrentDate();
    this.fetchEnumType();
    this.fetchFundList();
    if (this.$route.params.id) {
      api.fundProjectApply
        .get({ id: Number(this.$route.params.id) })
        .then((res) => {
          this.form = { ...res };
        });
    }
  }

  async fetchFundList() {
    await api.fund
      .getAll({ maxResultCount: 1000 })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  selectFund() {
    this.form.projectId = undefined;
    this.fetchFundProjectList();
  }

  async fetchFundProjectList() {
    if (this.form.fundId) {
      await api.fundProject
        .getAll({
          fundId: this.form.fundId,
        })
        .then((res: FundProjectDtoPagedResultDto) => {
          this.projectList = res.items ?? [];
        });
    }
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  getCurrentDate() {
    let nowDate = new Date();
    let date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate(),
    };
    this.applyTime = date.year + "年" + date.month + "月" + date.date + "日";
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.form.title) {
          this.$message.error("请填写请款标题！");
          this.btnDisabled = false;
          return;
        }
        if (!this.form.fundId) {
          this.$message.error("请选择申请基金！");
          this.btnDisabled = false;
          return;
        }
        if (this.form.moneyAmount != undefined && this.form.moneyAmount <= 0) {
          this.$message.error("请款金额必须大于0！");
          this.btnDisabled = false;
          return;
        }
        if (!this.form.appropriateReason) {
          this.$message.error("请填写拟办意见！");
          this.btnDisabled = false;
          return;
        }

        await api.fundAppropriateApply
          .create({
            body: this.form,
          })
          .then(() => {
            this.$router.back();
            this.$message.success("操作成功");
          })
          .catch(() => {
            this.btnDisabled = false;
          });
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  changeMoneyAmount() {
    this.moneyBig = this.smalltoBIG(this.form.moneyAmount);
  }

  smalltoBIG(n: any) {
    n = Number(n);
    let fraction = ["角", "分"];
    let digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    let unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"],
    ];
    let head = n < 0 ? "欠" : "";
    n = Math.abs(n);

    let s = "";

    for (let i = 0; i < fraction.length; i++) {
      s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
      ).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);

    for (let i = 0; i < unit[0].length && n > 0; i++) {
      let p = "";
      for (let j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    const result =
      head +
      s
        .replace(/(零.)*零元/, "元")
        .replace(/(零.)+/g, "零")
        .replace(/^整$/, "零元");
    return result;
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    appropriateReason: [
      {
        required: true,
        message: "拟办意见必填",
        trigger: "blur",
      },
    ],
    moneyAmount: [
      {
        required: true,
        message: "请款金额必填",
        trigger: "blur",
      },
    ],
    title: [
      {
        required: true,
        message: "请款标题必填",
        trigger: "blur",
      },
    ],
  };
}
